import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import EmbedVideo from '../components/EmbedVideo';

const WatchNow = () => (
  <Layout>
    <SEO title="Watch Now" />

    <div className="flex flex-col justify-center min-h-screen min-w-screen">
      <div className="mx-auto p-4 w-full sm:max-w-md md:max-w-xl xl:max-w-2xl 2xl:max-w-4xl">
        <h1 className="font-Oswald mb-12 text-2xl text-center md:text-4xl">
          How men are unlocking their hidden confidence and attracting top tier women.
          Watch below...
        </h1>

        <div className="bg-red mb-16 mx-auto p-4 rounded-md shadow-md xl:p-8">
          <EmbedVideo
            src="https://player.vimeo.com/video/597345315?h=aff4b7130f"
            title="intro"
            type="Vimeo"
          />
        </div>

        <div className="flex items-center text-center">
          <div className="bg-red flex-1 h-0.5" />

          <Link
            className="bg-red mx-4 px-8 py-4 rounded-md shadow-md text-white transition-opacity hover:opacity-70 md:mx-8"
            to="/apply"
          >
            Apply Now
          </Link>

          <div className="bg-red flex-1 h-0.5" />
        </div>
      </div>
    </div>
  </Layout>
);

export default WatchNow;
